import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IEntity, IUser } from '@lancelot-frontend/api';
import { SignedInUserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class UserRightsService {
  /**
   * Static functions:
   */

  static isAdmin = (user: IUser | null) => user?.roles.includes('ROLE_ADMIN');
  static isPro = (user: IUser | null) => user?.roles.includes('ROLE_PRO');
  static isHelpDesk = (user: IUser | null) =>
    user?.roles.includes('ROLE_HELPDESK');
  static canAccessAdmin = (user: IUser | null) =>
    UserRightsService.isAdmin(user) || UserRightsService.isHelpDesk(user);
  static canAccessTools = (user: IUser | null) =>
    UserRightsService.isAdmin(user) || user?.roles.includes('ROLE_TOOLS');
  static isBoardLibraryAdmin = (user: IUser | null) =>
    UserRightsService.isAdmin(user) ||
    user?.roles.includes('ROLE_BOARDLIBRARY_ADMIN');
  static isBoardLibraryContributor = (user: IUser | null) =>
    UserRightsService.isBoardLibraryAdmin(user) ||
    user?.roles.includes('ROLE_BOARDLIBRARY_QUALIF');
  static isBoardLibraryReader = (user: IUser | null) =>
    UserRightsService.isBoardLibraryContributor(user) ||
    user?.roles.includes('ROLE_BOARDLIBRARY_USER');
  static canAccessBoardLibrary = (user: IUser | null) =>
    UserRightsService.isBoardLibraryReader(user);
  static canUploadBoardLibraryCatalog = (user: IUser | null) =>
    UserRightsService.isBoardLibraryAdmin(user);
  static canDeleteBoardLibraryCatalog = (user: IUser | null) =>
    UserRightsService.isBoardLibraryAdmin(user);
  static canEditBoardLibraryBoard = (user: IUser | null) =>
    UserRightsService.isBoardLibraryContributor(user);
  static canDeleteBoardLibraryBoard = (user: IUser | null) =>
    UserRightsService.isBoardLibraryAdmin(user);

  /**
   * Signed in user role observables:
   */

  // User roles
  private readonly _isAdmin$ = new BehaviorSubject<boolean | undefined>(
    undefined,
  );
  get isAdmin$() {
    return this._isAdmin$.asObservable();
  }
  private readonly _isPro$ = new BehaviorSubject<boolean | undefined>(
    undefined,
  );
  get isPro$() {
    return this._isPro$.asObservable();
  }
  private readonly _isHelpDesk$ = new BehaviorSubject<boolean | undefined>(
    undefined,
  );
  get isHelpDesk$() {
    return this._isHelpDesk$.asObservable();
  }

  // Admin rights
  private readonly _canAccessAdmin$ = new BehaviorSubject<boolean | undefined>(
    undefined,
  );
  get canAccessAdmin$() {
    return this._canAccessAdmin$.asObservable();
  }
  canAccessCompetitionSettings = (entity: IEntity) =>
    this._isAdmin$.pipe(
      filter((isAdmin) => isAdmin !== undefined),
      map((isAdmin) => !!isAdmin && entity.type === 'federation'),
    );
  canAccessEntitiesSettings = (entity: IEntity) =>
    this._isAdmin$.pipe(
      filter((isAdmin) => isAdmin !== undefined),
      map((isAdmin) => !!isAdmin && entity.type === 'federation'),
    );
  canAccessUsers = (entity: IEntity) =>
    this._isAdmin$.pipe(
      filter((isAdmin) => isAdmin !== undefined),
      map((isAdmin) => !!isAdmin && entity.type === 'federation'),
    );

  // Tools rights
  private readonly _canAccessTools$ = new BehaviorSubject<boolean | undefined>(
    undefined,
  );
  get canAccessTools$() {
    return this._canAccessTools$.asObservable();
  }

  // Board library roles
  private readonly _isBoardLibraryAdmin$ = new BehaviorSubject<
    boolean | undefined
  >(undefined);
  get isBoardLibraryAdmin$() {
    return this._isBoardLibraryAdmin$.asObservable();
  }
  private readonly _isBoardLibraryContributor$ = new BehaviorSubject<
    boolean | undefined
  >(undefined);
  get isBoardLibraryContributor$() {
    return this._isBoardLibraryContributor$.asObservable();
  }
  private readonly _isBoardLibraryReader$ = new BehaviorSubject<
    boolean | undefined
  >(undefined);
  get isBoardLibraryReader$() {
    return this._isBoardLibraryReader$.asObservable();
  }

  // Board library rights
  private readonly _canAccessBoardLibrary$ = new BehaviorSubject<
    boolean | undefined
  >(undefined);
  get canAccessBoardLibrary$() {
    return this._canAccessBoardLibrary$.asObservable();
  }
  private readonly _canUploadBoardLibraryCatalog$ = new BehaviorSubject<
    boolean | undefined
  >(undefined);
  get canUploadBoardLibraryCatalog$() {
    return this._canUploadBoardLibraryCatalog$.asObservable();
  }
  private readonly _canDeleteBoardLibraryCatalog$ = new BehaviorSubject<
    boolean | undefined
  >(undefined);
  get canDeleteBoardLibraryCatalog$() {
    return this._canDeleteBoardLibraryCatalog$.asObservable();
  }
  private readonly _canEditBoardLibraryBoard$ = new BehaviorSubject<
    boolean | undefined
  >(undefined);
  get canEditBoardLibraryBoard$() {
    return this._canEditBoardLibraryBoard$.asObservable();
  }
  private readonly _canDeleteBoardLibraryBoard$ = new BehaviorSubject<
    boolean | undefined
  >(undefined);
  get canDeleteBoardLibraryBoard$() {
    return this._canDeleteBoardLibraryBoard$.asObservable();
  }

  constructor() {
    inject(SignedInUserService)
      .signedInUser$.pipe(takeUntilDestroyed())
      .subscribe((user) => {
        // Set user roles
        this._isAdmin$.next(UserRightsService.isAdmin(user));
        this._isPro$.next(UserRightsService.isPro(user));
        this._isHelpDesk$.next(UserRightsService.isHelpDesk(user));

        // Set admin rights
        this._canAccessAdmin$.next(UserRightsService.canAccessAdmin(user));

        // Set tools rights
        this._canAccessTools$.next(UserRightsService.canAccessTools(user));

        // Set board library roles
        this._isBoardLibraryAdmin$.next(
          UserRightsService.isBoardLibraryAdmin(user),
        );
        this._isBoardLibraryContributor$.next(
          UserRightsService.isBoardLibraryContributor(user),
        );
        this._isBoardLibraryReader$.next(
          UserRightsService.isBoardLibraryReader(user),
        );

        // Set board library rights
        this._canAccessBoardLibrary$.next(
          UserRightsService.canAccessBoardLibrary(user),
        );
        this._canUploadBoardLibraryCatalog$.next(
          UserRightsService.canUploadBoardLibraryCatalog(user),
        );
        this._canDeleteBoardLibraryCatalog$.next(
          UserRightsService.canDeleteBoardLibraryCatalog(user),
        );
        this._canEditBoardLibraryBoard$.next(
          UserRightsService.canEditBoardLibraryBoard(user),
        );
        this._canDeleteBoardLibraryBoard$.next(
          UserRightsService.canDeleteBoardLibraryBoard(user),
        );
      });
  }
}
